<template>
  <WeContainer card="" seo-bar="" v-if="ready">
    <WeCard class="shadow-sm sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col-12 col-md text-center text-md-left">
          <h4 class="mb-md-0">{{ getTitle }}</h4>
        </div>
        <div class="col-12 col-md-auto text-center">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-bind:permission="checkPermission"
            v-on:submit="submitForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>
    <div class="row">
      <!-- Left Panel -->
      <div class="col-12 mb-3 col-lg-6 mb-lg-0">
        <WeCard class="mb-3">
          <!-- Card Title -->
          <h5 class="custom-card-title">
            <i class="far fa-list-alt"></i>
            <span>Genel Bilgiler</span>
          </h5>
          <hr />
          <!-- ./Card Title -->
          <div class="row">
            <!-- Member Type -->
            <div class="col-12 col-lg">
              <div class="form-group">
                <label for="member-type" class="custom-label">Üye Tipi</label>
                <select
                  name="member-type"
                  id="member-type"
                  class="custom-select"
                  v-on:change="groupList"
                  v-model="form.type"
                >
                  <option value="-1">Seçiniz</option>
                  <option
                    v-for="(type, index) in membertype.list"
                    v-bind:key="type.id"
                    v-bind:value="index"
                  >
                    {{ type.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- ./Member Type -->

            <!-- Member Group -->
            <div class="col-12 col-lg">
              <div class="form-group">
                <label for="member-group" class="custom-label">Üye Grubu</label>
                <select
                  name="member-group"
                  id="member-group"
                  class="custom-select"
                  v-bind:disabled="!memberGroupList.length"
                  v-model="form.group"
                >
                  <option value="-1">{{ getMemberOptionText() }}</option>
                  <option
                    v-for="group in memberGroupList"
                    v-bind:key="group.id"
                    v-bind:value="group.id"
                  >
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>
            <!-- ./Member Group -->
          </div>

          <div class="row">
            <!-- Account Number -->
            <div class="col-12">
              <div class="form-group">
                <WeInput
                  label="Cari Hesap Kodu"
                  name="current_code"
                  placeholder="Cari Hesap Kodu"
                  v-model="form.accountNumber"
                />
              </div>
            </div>
            <!-- ./Account Number -->

            <!-- Name -->
            <div class="col-12 col-md-6">
              <WeInput
                label="Ad"
                name="first_name"
                placeholder="Ad"
                v-bind:required="true"
                v-model="form.firstName"
                v-bind:error="$v.form.firstName.$error"
              />
            </div>
            <!-- ./Name -->

            <!-- Surname -->
            <div class="col-12 col-md-6">
              <WeInput
                label="Soyad"
                name="last_name"
                placeholder="Soyad"
                v-bind:required="true"
                v-model="form.lastName"
                v-bind:error="$v.form.lastName.$error"
              />
            </div>
            <!-- ./Surname -->

            <!-- Gender -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="gender" class="custom-label">Cinsiyet</label>
                <select
                  name="gender"
                  id="gender"
                  class="custom-select"
                  v-model="form.gender"
                >
                  <option value="K">Kadın</option>
                  <option value="E">Erkek</option>
                </select>
              </div>
            </div>
            <!-- ./Gender -->
            <!-- Birthdate -->
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="birthdate" class="custom-label">Doğum Tarihi</label>
                <input
                  type="date"
                  class="form-control"
                  name="birthdate"
                  v-model="form.birthdate"
                />
              </div>
            </div>
            <!-- ./Birthdate -->
          </div>

          <!-- Phone Numbers -->
          <div class="row mb-3">
            <!-- Phone Number -->
            <div class="col-12 col-xl mb-3 mb-xl-0">
              <WePhone
                v-model="form.phoneNumber"
                v-bind:label="$t('phoneNumber')"
              />
            </div>
            <!-- ./Phone Number -->

            <!-- Mobile Phone Number -->
            <div class="col-12 col-xl">
              <WePhone v-bind:label="$t('gsm')" v-model="form.gsm" />
            </div>
            <!-- ./Mobile Phone Number -->
          </div>
          <!-- ./Phone Number -->

          <!-- Member Status -->
          <label class="custom-label">Üye Durumu</label>
          <WeSwitch v-model="form.is_active" />
          <!-- Member Status -->

          <!-- TCKN -->
          <div class="form-group" v-if="form.type == 0 || form.type > 2">
            <div class="row">
              <div class="col">
                <label for="tckn" class="custom-label">TCKN</label>
              </div>
              <div class="col-12 col-md text-md-right">
                <div class="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="citizen"
                    v-model="form.notCitizen"
                  />
                  <label class="custom-control-label" for="citizen"
                    >T.C Vatandaşı Değil</label
                  >
                </div>
              </div>
            </div>

            <input
              type="text"
              name="tckn"
              class="form-control"
              placeholder="TCKN"
              v-model="form.identifier"
            />
          </div>
          <!-- ./TCKN -->
        </WeCard>

        <!-- Firm Info -->
        <WeCard class="mb-3" v-if="form.type == 1 || form.type == 2">
          <!-- Card Title -->
          <h5 class="custom-card-title">
            <i class="far fa-building"></i>
            <span>Firma Bilgileri</span>
          </h5>
          <hr />
          <!-- ./Card Title -->

          <div class="row">
            <!-- Firm Name -->
            <div class="col-12">
              <div class="form-group">
                <WeInput
                  type="text"
                  name="firm_name"
                  placeholder="Firma Adı"
                  v-model="form.firm.name"
                  label="Firma Adı"
                />
              </div>
            </div>
            <!-- ./Firm Name -->

            <!-- Tax Office -->
            <div class="col-12 col-md">
              <div class="form-group">
                <WeInput
                  type="text"
                  name="tax_office"
                  placeholder="Vergi Dairesi"
                  v-model="form.firm.taxOffice"
                  label="Vergi Dairesi"
                />
              </div>
            </div>
            <!-- ./Tax Office -->

            <!-- Tax Number -->
            <div class="col-12 col-md">
              <div class="form-group">
                <WeInput
                  type="text"
                  name="tax_number"
                  placeholder="Vergi Numarası"
                  label="Vergi Numarası"
                  v-model="form.firm.taxNumber"
                />
              </div>
            </div>
            <!-- ./Tax Number -->
          </div>
        </WeCard>
        <!-- ./Firm Info -->

        <WeCard class="mb-3">
          <!-- Card Title -->
          <h5 class="custom-card-title">
            <i class="fas fa-sign-in-alt"></i>
            <span>Giriş Bilgileri</span>
          </h5>
          <hr />
          <!-- ./Card Title -->

          <!-- E-mail -->
          <div class="form-group">
            <WeInput
              label="E-Posta"
              name="email"
              placeholder="E-Posta Adresi"
              v-bind:required="true"
              v-model="form.login.email"
              v-bind:error="$v.form.login.email.$error"
            />
          </div>
          <!-- ./E-mail -->

          <!-- Password & Confirmation -->
          <div class="row">
            <!-- Password -->
            <div class="col-12 col-md">
              <div class="form-group">
                <WeInput
                  type="password"
                  id="password"
                  name="password"
                  autocomplete="new-password"
                  v-model="form.login.password"
                  label="Şifre"
                />
              </div>
            </div>
            <!-- ./Password -->

            <!-- Password Confirmation -->
            <div class="col-12 col-md">
              <div class="form-group">
                <WeInput
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  autocomplete="new-password"
                  v-model="form.login.passwordConfirmation"
                  label="Şifre Tekrar"
                />
              </div>
            </div>
            <!-- ./Password Confirmation -->
          </div>
          <!-- ./Password & Confirmation -->

          <!-- Generate Password -->
          <div class="form-group">
            <label for="generate-password" class="custom-label"
              >Şifre Üret</label
            >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Şifre Üret"
                v-model="generatedPassword"
              />
              <div class="input-group-append">
                <span
                  v-if="generatedPassword && generatedPassword.length"
                  v-tooltip.bottom="'Şifreyi Onayla'"
                  v-on:click="applyPassword(generatedPassword)"
                  class="btn input-group-text"
                  ><i class="fas fa-check"></i
                ></span>
                <span
                  class="btn btn-success"
                  v-on:click="generatePassword"
                  v-tooltip.bottom="'Şifreyi Üret'"
                  ><i class="fas fa-random"></i
                ></span>
              </div>
            </div>
          </div>
          <!-- ./Generate Password -->

          <div class="alert alert-info" v-if="this.$route.params.id">
            <p class="mb-0">
              <i class="fas fa-info-circle"></i> Şifre alanları boş
              bırakılabilir.
            </p>
          </div>
        </WeCard>
      </div>
      <!-- ./Left Panel -->

      <!-- Right Panel -->
      <div class="col-12 mb-3 col-lg-6 mb-lg-0">
        <WeCard class="mb-3" v-if="form.type == 2">
          <!-- Card Title -->
          <h5 class="custom-card-title">
            <i class="fas fa-user-check"></i>
            <span>Çalışma Koşulları</span>
          </h5>
          <hr />
          <!-- ./Card Title -->

          <!-- Is Open Account -->
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="is-open-account"
              v-model="form.is_open_account"
            />
            <label class="custom-control-label" for="is-open-account"
              >Açık Hesap</label
            >
          </div>
          <!-- ./Is Open Account -->
        </WeCard>

        <!-- Notification Settings -->
        <WeCard class="mb-3">
          <!-- Card Title -->
          <h5 class="custom-card-title">
            <i class="fas fa-bell"></i>
            <span>Bildirim Seçenekleri</span>
          </h5>
          <hr />
          <!-- ./Card Title -->

          <div class="row">
            <div class="col-auto">
              <!-- E-Mail Notification -->
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="email-notification"
                  v-model="form.notification.email"
                />
                <label class="custom-control-label" for="email-notification"
                  >E-Posta</label
                >
              </div>
              <!-- ./E-Mail Notification -->
            </div>
            <div class="col-auto">
              <!-- SMS Notification -->
              <div class="custom-control custom-switch">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="sms-notification"
                  v-model="form.notification.sms"
                />
                <label class="custom-control-label" for="sms-notification"
                  >SMS</label
                >
              </div>
              <!-- ./SMS Notification -->
            </div>
          </div>
        </WeCard>
        <!-- ./Notification Settings -->

        <AddressForm v-bind:form="form" />
      </div>
      <!-- ./Right Panel -->
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required, requiredIf, email } from "vuelidate/lib/validators";
import AddressForm from "./AddressForm/Index";

export default {
  name: "Detail",
  components: {
    AddressForm,
  },
  data() {
    return {
      generatedPassword: null,
      ready: false,
      submit: false,
      memberTypeList: [],
      memberGroupList: [],
      form: {
        accountNumber: null,
        firstName: null,
        lastName: null,
        gender: "E",
        birthdate: null,
        phoneNumber: null,
        gsm: null,
        identifier: null,
        notCitizen: false,
        is_open_account: false,
        is_active: null,
        // Firm Information
        firm: {
          name: null,
          taxOffice: null,
          taxNumber: null,
        },
        tcn: null,

        // Login credentials
        login: {
          email: null,
          password: null,
          passwordConfirmation: null,
          generatedPassword: null,
        },

        // Member Type & Group
        type: -1,
        group: -1,

        addressList: [],

        // Address'
        address: {
          id: 0,
          contact_type: null,
          name: null,
          country: null,
          city: null,
          district: null,
          neighborhood: null,
          postal_code: null,
          address: null,
          first_name: null,
          last_name: null,
          email: null,
          phone: null,
        },

        // Notification
        notification: {
          email: true,
          sms: false,
        },
      },
      cleanData: {},
      cleanRequest: {},
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      login: {
        email: {
          required,
        },
      },
    },

    // memberPassword: {
    //   required: function() {
    //     return this.$route.params.id === undefined;
    //   },
    // },
  },
  methods: {
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    ...mapActions("member", [
      "create",
      "update",
      "getById",
      "getMemberTypes",
      "getMemberGroupList",
    ]),
    applyPassword(newPassword) {
      this.form.login.password = newPassword;
      this.form.login.passwordConfirmation = newPassword;
    },

    setMemberGroups(result) {
      if (result.length > 0) {
        this.memberGroupList = result;
      } else {
        this.form.group = "-1";
        this.memberGroupList = [];
      }
    },

    validateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      this.form["memberTypeList"] = this.membertype.list;
      this.form["contactTypes"] = this.session.contactTypes;
      if (this.$route.params.id) {
        this.cleanRequest = helper.difference(this.form, this.cleanData, [
          "memberTypeList",
          "contactTypes",
          "contacts",
          "addressList",
        ]);
        this.updateMember();
      } else {
        this.addMember();
      }
    },
    addMember() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Müşteriler listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateMember() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Müşteriler listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getMemberOptionText() {
      let text = "";
      if (this.memberGroupList.length || this.form.type == -1) {
        text = "Seçiniz";
      } else {
        text = "Mevcut Değil";
      }

      return text;
    },
    groupList() {
      let typeId = null;
      if (this.form.type != -1) {
        typeId = this.membertype.list[this.form.type].id;
        this.getMemberGroupList({
          id: typeId,
          onSuccess: (result) => {
            this.setMemberGroups(result);
          },
        });
      } else {
        this.memberGroupList = [];
      }
    },
    getMemberById() {
      this.contactType();
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.groupList();
            this.cleanData = helper.clone(this.form);
            this.ready = true;
            if (this.$route.params.id) {
              this.form.address.first_name = this.form.firstName;
              this.form.address.last_name = this.form.lastName;
              this.form.address.email = this.form.login.email;
              this.form.address.phone = this.form.phoneNumber ?? this.form.gsm;
            }
          },
        });
      } else {
        this.ready = true;
      }
    },

    redirectToList() {
      this.$router.push("/members");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    generatePassword() {
      this.generatedPassword = helper.generatePassword(10);
    },
    contactType() {
      if (!this.form.address.contact_type) {
        this.form.address.contact_type = this.session.contactTypes[1];
      }
    },
  },
  watch: {
    "this.form": {
      handler: function (newValue, oldValue) {
        sessionStorage.setItem("changed", true);
      },
    },
    deep: true,
  },
  computed: {
    ...mapState(["member", "shared", "session", "membertype"]),
    getTitle() {
      return this.$route.params.id ? "MÜŞTERİ DÜZENLE" : "MÜŞTERİ EKLE";
    },
    checkPermission() {
      return permission.check("member", "u");
    },
  },
  mounted() {
    sessionStorage.removeItem("changed");
    this.$store.dispatch("membertype/getList");
    this.getMemberById();
  },
};
</script>