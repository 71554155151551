<template>
  <div>
    <!-- Address Create -->
    <WeCard class="mb-3">
      <!-- Card Title -->
      <div class="row align-items-center">
        <div class="col">
          <h5 class="custom-card-title">
            <i class="far fa-address-card"></i>
            <span>Adres</span>
          </h5>
        </div>
        <div class="col-auto">
          <button
            class="btn btn-sm btn-outline-primary"
            v-on:click="newAddress"
            v-if="!updateMode"
          >
            <i class="fas fa-plus"></i>
            <span class="ml-2">Ekle</span>
          </button>
          <template v-else>
            <button
              class="btn btn-sm btn-info"
              v-on:click="reUpdateAddress"
              v-tooltip.bottom="'Değişiklikleri Onayla'"
            >
              <i class="fas fa-save"></i>
              <span class="ml-2">Onayla</span>
            </button>
            <button
              class="btn btn-sm alert-danger ml-2"
              v-on:click="clearAddressForm"
              v-tooltip.bottom="'Formu Temizle'"
            >
              <i class="fas fa-trash"></i>
            </button>
          </template>
        </div>
      </div>
      <hr />
      <!-- ./Card Title -->

      <!-- Title -->
      <div class="form-group">
        <WeInput
          type="text"
          name="address-title"
          id="address-title"
          placeholder="Ev, İş Yeri vb."
          v-model="form.address.name"
          label="Adres Başlığı"
        />
      </div>
      <!-- ./Title -->

      <div class="row">
        <!-- First Name -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <WeInput
              type="text"
              name="address-first_name"
              id="address-first_name"
              label="Ad"
              placeholder="Ad"
              v-model="form.address.first_name"
            />
          </div>
        </div>
        <!-- ./First Name -->

        <!-- Last Name -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <WeInput
              type="text"
              name="address-last_name"
              id="address-last_name"
              placeholder="Soyad"
              v-model="form.address.last_name"
              label="Soyad"
            />
          </div>
        </div>
        <!-- ./Last Name -->

        <!-- Email -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <WeInput
              label="E-Posta"
              name="email"
              placeholder="E-Posta Adresi"
              v-bind:required="true"
              v-model="form.address.email"
            />
          </div>
        </div>
        <!-- ./Email -->

        <!-- Phone -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <WePhone
              v-model="form.address.phone"
              v-bind:data="form.address.phone"
              v-bind:label="$t('phoneNumber')"
            />
          </div>
        </div>
        <!-- ./Phone -->

        <!-- Address Type -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label class="custom-label" for="country">Adres Tipi</label>
            <v-select
              label="name"
              placeholder="Adres Tipi"
              v-bind:options="contactTypess"
              v-model="form.address.contact_type"
            ></v-select>
          </div>
        </div>
        <!-- ./Address Type -->

        <!-- Country -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label class="custom-label" for="country">Ülke</label>
            <v-select
              label="name"
              placeholder="Ülke"
              v-bind:options="countries"
              v-bind:disabled="!countries.length"
              v-model="form.address.country"
              v-on:input="getCityList"
            ></v-select>
          </div>
        </div>
        <!-- ./Country -->

        <!-- City -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label class="custom-label" for="city">Şehir</label>
            <v-select
              label="name"
              placeholder="Şehir"
              v-bind:options="cities"
              v-bind:disabled="!cities.length"
              v-model="form.address.city"
              v-on:input="getDistrictList"
            ></v-select>
          </div>
        </div>
        <!-- ./City -->

        <!-- District -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label class="custom-label" for="district">İlçe</label>
            <v-select
              label="name"
              placeholder="İlçe"
              v-bind:options="districts"
              v-bind:disabled="!districts.length"
              v-model="form.address.district"
              v-on:input="getNeighborhoodList"
            ></v-select>
          </div>
        </div>
        <!-- ./District -->

        <!-- Neighborhood -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <label class="custom-label" for="neighborhood">Semt</label>
            <v-select
              label="name"
              placeholder="Semt"
              v-bind:options="neighborhoods"
              v-bind:disabled="!neighborhoods.length"
              v-model="form.address.neighborhood"
              v-on:input="setPostalCode"
            ></v-select>
          </div>
        </div>
        <!-- ./Neighborhood -->

        <!-- Postal Code -->
        <div class="col-12 col-xl-6">
          <div class="form-group">
            <WeInput
              type="text"
              name="postal-code"
              id="postal-code"
              placeholder="Posta Kodu"
              v-model="form.address.postal_code"
              label="Posta Kodu"
            />
          </div>
        </div>
        <!-- ./Postal Code -->
      </div>

      <!-- Full Address -->
      <div class="form-group mb-0">
        <label class="custom-label" for="full-address">Açık Adres</label>
        <textarea
          name="full-address"
          id="full-address"
          class="form-control"
          placeholder="Açık Adres"
          v-model="form.address.address"
        ></textarea>
      </div>
      <!-- ./Full Address -->
    </WeCard>
    <!-- Address Create -->

    <!-- Address List -->
    <WeCard v-if="form.addressList && form.addressList.length">
      <h5 class="custom-card-title">
        <i class="fas fa-list"></i>
        <span>Adres Listesi</span>
      </h5>
      <hr />

      <div class="row">
        <div
          class="col-12 col-xl mb-3"
          v-for="(address, index) in form.addressList"
          v-bind:class="
            form.addressList.length > 2 ? 'col-12 col-md-12 col-lg-12' : ''
          "
          v-bind:key="address.id"
        >
          <!-- Every Single Address -->
          <WeCard class="address-card">
            <div class="row align-items-center">
              <div class="col">
                <h6>
                  {{ address.name }}
                </h6>
                <p class="mb-0 font-weight-bold">
                  {{ address.first_name }} {{ address.last_name }}
                </p>
                <p class="font-14 mb-0">
                  <template v-if="address.neighborhood">
                    {{ address.neighborhood.name }}
                  </template>
                  {{ address.address }}
                  {{ address.district.name }} /
                  {{ address.city.name }}
                  {{ address.postal_code }}
                </p>
              </div>
              <div class="col-auto">
                <div class="row">
                  <div class="col pr-0">
                    <span
                      class="btn btn-sm alert-primary"
                      v-on:click="fillAddress(index)"
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                  <div class="col pl-1">
                    <span
                      class="btn btn-sm alert-danger"
                      v-on:click="deleteAddress(address)"
                    >
                      <i class="fas fa-trash"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </WeCard>
          <!-- ./Every Single Address -->
        </div>
      </div>
    </WeCard>
    <!-- ./Address List -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "AddressForm",
  props: {
    form: {
      default: () => {},
    },
  },
  data() {
    return {
      updateMode: false,
      addressIndex: -1,
      countries: [],
      cities: [],
      districts: [],
      neighborhoods: [],
    };
  },
  methods: {
    ...mapActions("member", [
      "getCountries",
      "getCities",
      "getDistricts",
      "getNeighborhoods",
    ]),
    newAddress() {
      const require = this.checkAddressRequires();
      if (require) {
        this.createAddress();
        this.clearAddressForm();
      }
    },
    fillAddress(index) {
      this.editAddress(index);
      this.addressIndex = index;
      this.updateMode = true;
    },
    reUpdateAddress() {
      const require = this.checkAddressRequires();
      if (require) {
        let index = this.addressIndex;
        this.updateAddress(index);
        this.clearAddressForm();

        this.addressIndex = -1;
      }
    },
    deleteAddress(address) {
      let message = `<b>${address.name}</b> adresini silmek istediğinize emin misiniz ?`;
      this.$swal({
        html: message,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Sil",
        showCancelButton: true,
        cancelButtonText: "İptal",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          this.removeAddress(address.id);
        }
      });
    },
    createAddress() {
      this.form.address.id = helper.getRandomInteger(10000, 99999);
      this.form.addressList.push(this.form.address);
    },
    removeAddress(id) {
      helper.arrayRemove(this.form.addressList, "id", id);
    },
    setPostalCode(member) {
      if (member && member != -1) {
        const neighborhood = this.form.address.neighborhood;
        if (neighborhood.hasOwnProperty("code")) {
          this.form.address.postal_code = neighborhood.code;
        }
      } else {
        this.form.address.postal_code = null;
      }
    },
    editAddress(member) {
      const addressList = this.form.addressList;
      if (addressList) {
        if (addressList.hasOwnProperty(member)) {
          this.form.address = helper.clone(addressList[member]);
          this.getCityList();
          this.getDistrictList();
          this.getNeighborhoodList();
        }
      }
    },
    clearAddressForm() {
      this.cities = [];
      this.districts = [];
      this.neighborhoods = [];
      this.form.address = {
        contact_type: null,
        name: null,
        country: null,
        city: null,
        district: null,
        neighborhood: null,
        postal_code: null,
        address: null,
        first_name: null,
        last_name: null,
      };
      this.updateMode = false;
    },
    updateAddress(member) {
      let addressList = this.form.addressList;
      if (addressList) {
        if (addressList.hasOwnProperty(member)) {
          addressList[member] = helper.clone(this.form.address);
        }
      }
    },

    getCityList() {
      this.cities = [];
      const countryId =
        this.form.address.country && this.form.address.country.id;
      if (countryId) {
        this.getCities({
          id: countryId,
          onSuccess: (result) => {
            this.cities = helper.clone(result);
          },
        });
      }
    },
    getDistrictList() {
      this.districts = [];
      const cityId = this.form.address.city && this.form.address.city.id;
      if (cityId && cityId != -1) {
        this.getDistricts({
          id: cityId,
          onSuccess: (result) => {
            this.districts = helper.clone(result);
          },
        });
      }
    },
    getNeighborhoodList() {
      this.neighborhoods = [];
      const districtId =
        this.form.address.district && this.form.address.district.id;
      if (districtId && districtId != -1) {
        this.getNeighborhoods({
          id: districtId,
          onSuccess: (result) => {
            this.neighborhoods = helper.clone(result);
          },
        });
      }
    },
    checkAddressRequires() {
      const address = this.form.address;
      if (address.name == null) {
        this.$swal({
          title: "Lütfen Adres Başlığı Girin",
          icon: "error",
        });
      } else if (address.country == null) {
        this.$swal({
          title: "Lütfen Ülke seçin",
          icon: "error",
        });
      } else if (address.city == null) {
        this.$swal({
          title: "Lütfen Şehir seçin",
          icon: "error",
        });
      } else if (address.district == null) {
        this.$swal({
          title: "Lütfen İlçe seçin",
          icon: "error",
        });
      } else if (address.country && address.country.name == 'Türkiye' && address.neighborhood == null) {
        this.$swal({
          title: "Lütfen Semt seçin",
          icon: "error",
        });
      } else if (address.address == null) {
        this.$swal({
          title: "Lütfen Açık Adres girin",
          icon: "error",
        });
      } /*else if (!address.first_name) {
        this.$swal({
          title: "Lütfen Ad girin",
          icon: "error",
        });
      } else if (!address.last_name) {
        this.$swal({
          title: "Lütfen Soyad girin",
          icon: "error",
        });
      }*/ else {
        return true;
      }
    },
    getCountryList() {
      if (!this.countries.length) {
        this.getCountries({
          onSuccess: (result) => {
            this.countries = helper.clone(result);
          },
        });
      }
    },
  },
  computed: {
    ...mapState(["session"]),

    contactTypess() {
      return this.session.contactTypes;
    },
  },
  mounted() {
    this.getCountryList();
  },
};
</script>
<style lang="scss" scoped>
.address-card {
  transition: all 400ms ease-in-out;
}
.address-card:hover {
  border-color: #004085;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
}
</style>